import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation } from 'react-router-dom';

import firstImg from '../../assets/carousel/produtos/1.png';
import secondImg from '../../assets/carousel/produtos/2.png';
import thirdImg from '../../assets/carousel/produtos/3.png';
import fourthImg from '../../assets/carousel/produtos/4.png';
import fiveImg from '../../assets/carousel/produtos/5.png';
import sixthImg from '../../assets/carousel/produtos/6.png';

import Button from '../../components/Button';
import SlickSlider from '../../components/Slider';

import { Main, Container, SubMenu } from './styles';

type ProdutosProps = {
  showCompostosDeBorrachaBool?: boolean;
}

export function Produtos({ showCompostosDeBorrachaBool = true }: ProdutosProps) {
  const [showCompostosDeBorracha, setShowCompostosDeBorracha] = useState(showCompostosDeBorrachaBool);

  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/produtos/compostos-de-borracha") {
      setShowCompostosDeBorracha(true);
    }
  }, [location]);

  return (
    <Main>
      <Helmet>
        <title>Produtos Zanaflex</title>
        <meta name="description" content="Compostos de Borracha - Zanaflex" />
        <meta name="keywords" content="Produtos, Zanaflex, Borracha, Composto de Borracha" />
      </Helmet>

      <SubMenu>
        <Link to="/produtos/compostos-de-borracha" className={showCompostosDeBorracha ? 'submenu-active' : 'submenu-disabled'} ><h1>Compostos de Borracha</h1></Link>
      </SubMenu>
      <Container data-aos="fade-left">
        <div>
          <h2>Compostos</h2>
          <ul>
            <li>Compostos de Borracha sem aceleração “Masterbatch”</li>
            <li>Pré-dispersos em Borracha</li>
            <li>Master de Enxofre</li>
            <li>Compostos de Borracha acelerados, prontos para a fabricação do produto final “Finalbatch”</li>
            <li>Compostos de Borracha conforme especificação ou formulação do cliente- Master de Acelerador</li>
          </ul>
          <h2>Principais Aplicações</h2>
          <div className="applications">
            <ul>
              <li>Aeroespacial</li>
              <li>Agrícola</li>
              <li>Automotivo</li>
              <li>Calçados</li>
              <li>Construção Civil</li>
              <li>Farmacêutico</li>
            </ul>
            <ul>
              <li>Sistemas de Vedação</li>
              <li>Especialidades Industriais</li>
              <li>Produtos de Mineração</li>
              <li>Correia Transportadora</li>
              <li>Outros</li>
            </ul>
          </div>
          <div>
            <Button pathname="/logistica/saida-dos-compostos" content="Saída dos Compostos" />
            <Button pathname="/logistica/formatos-dos-compostos" content="Formatos dos Compostos" />
          </div>
        </div>
        <div className="sliderProducts">
          <SlickSlider
            firstImgURL={firstImg}
            secondImgURL={secondImg}
            thirdImgURL={thirdImg}
            fourthImgURL={fourthImg}
            fiveImgURL={fiveImg}
            sixthImgURL={sixthImg} />
        </div>
      </Container>
    </Main>
  );
}

export function ProdutosES({ showCompostosDeBorrachaBool = true }: ProdutosProps) {
  const [showCompostosDeBorracha, setShowCompostosDeBorracha] = useState(showCompostosDeBorrachaBool);

  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/produtos/compostos-de-borracha/es-ES") {
      setShowCompostosDeBorracha(true);
    }
  }, [location]);

  return (
    <Main>
      <Helmet>
        <title>Productos Zanaflex</title>
        <meta name="description" content="Compuestos de caucho - Zanaflex" />
        <meta name="keywords" content="Productos, Zanaflex, Caucho, Compuesto de Caucho" />
      </Helmet>

      <SubMenu>
        <Link to="/produtos/compostos-de-borracha/es-ES" className={showCompostosDeBorracha ? 'submenu-active' : 'submenu-disabled'} ><h1>Compuestos de caucho</h1></Link>
      </SubMenu>
      <Container data-aos="fade-left">
        <div>
          <h2>Compuestos</h2>
          <ul>
            <li>Compuestos de Caucho sin aceleración “Masterbatch”</li>
            <li>Pre-disperso en Caucho</li>
            <li>Maestro de azufre</li>
            <li>Compuestos de caucho Acelerados, listos para fabricar el producto final “Finalbatch”</li>
            <li>Compuestos de caucho según especificación o formulación del cliente - Accelerator Master</li>
          </ul>
          <h2>Aplicaciones principaless</h2>
          <div className="applications">
            <ul>
              <li>Aeroespacial</li>
              <li>Agrícola</li>
              <li>Automotor</li>
              <li>Zapatos</li>
              <li>Construcción civil</li>
              <li>Farmacéutico</li>
            </ul>
            <ul>
              <li>Sistemas de Sellado</li>
              <li>Especialidades Industriales</li>
              <li>Productos mineros</li>
              <li>Cinta transportadora</li>
              <li>Otros</li>
            </ul>
          </div>
          <div>
            <Button pathname="/logistica/saida-dos-compostos/es-ES" content="Salida de Compuestos" />
            <Button pathname="/logistica/formatos-dos-compostos/es-ES" content="Formatos compuestos" />
          </div>
        </div>
        <div>
          <SlickSlider
            firstImgURL={firstImg}
            secondImgURL={secondImg}
            thirdImgURL={thirdImg}
            fourthImgURL={fourthImg}
            fiveImgURL={fiveImg}
            sixthImgURL={sixthImg} />
        </div>
      </Container>
    </Main>
  );
}

export function ProdutosUS({ showCompostosDeBorrachaBool = true }: ProdutosProps) {
  const [showCompostosDeBorracha, setShowCompostosDeBorracha] = useState(showCompostosDeBorrachaBool);

  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/produtos/compostos-de-borracha/en-US") {
      setShowCompostosDeBorracha(true);
    }
  }, [location]);

  return (
    <Main>
      <Helmet>
        <title>Zanaflex Products</title>
        <meta name="description" content="Rubber Compounds - Zanaflex" />
        <meta name="keywords" content="Products, Zanaflex, Rubber, Rubber Compound" />
      </Helmet>

      <SubMenu>
        <Link to="/produtos/compostos-de-borracha/en-US" className={showCompostosDeBorracha ? 'submenu-active' : 'submenu-disabled'} ><h1>Rubber Compounds</h1></Link>
      </SubMenu>
      <Container data-aos="fade-left">
        <div>
          <h2>Compounds</h2>
          <ul>
            <li>Rubber Compounds without “Masterbatch” acceleration</li>
            <li>Pre-dispersed in Rubber</li>
            <li>Sulfur Master</li>
            <li>Accelerated Rubber Compounds, ready to manufacture the final “Finalbatch” product</li>
            <li>Rubber Compounds as per customer specification or formulation - Accelerator Master</li>
          </ul>
          <h2>Main Applications</h2>
          <div className="applications">
            <ul>
              <li>Aerospace</li>
              <li>Agricultural</li>
              <li>Automotive</li>
              <li>Shoes</li>
              <li>Construction</li>
              <li>Pharmaceutical</li>
            </ul>
            <ul>
              <li>Sealing Systems</li>
              <li>Industrial Specialties</li>
              <li>Mining Products</li>
              <li>Conveyor belt</li>
              <li>Others</li>
            </ul>
          </div>
          <div>
            <Button pathname="/logistica/saida-dos-compostos/en-US" content="Output of Compounds" />
            <Button pathname="/logistica/formatos-dos-compostos/en-US" content="Compound Formats" />
          </div>
        </div>
        <div>
          <SlickSlider
            firstImgURL={firstImg}
            secondImgURL={secondImg}
            thirdImgURL={thirdImg}
            fourthImgURL={fourthImg}
            fiveImgURL={fiveImg}
            sixthImgURL={sixthImg} />
        </div>
      </Container>
    </Main>
  );
}
