/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import emailjs from '@emailjs/browser';
import { Main, Container, SubMenu, Localization, Form, TrabalheConosco } from './styles';
import { Input } from '../../components/Input';
import { Select } from '../../components/Select';
import { TextArea } from '../../components/TextArea';
import { Helmet } from 'react-helmet';
import emailApi from '../services/api';
import TrabalheConoscoForm from './curriculum';

type ContatoProps = {
  showFaleConoscoBool?: boolean;
  showLocalizacaoBool?: boolean;
  showTrabalheConoscoBool?: boolean;
}

export function Contato({ showFaleConoscoBool = true, showLocalizacaoBool = false, showTrabalheConoscoBool = false }: ContatoProps) {
  const [emailLoading, setEmailLoading] = useState(false);
  const [emailSuccess, setEmailSuccess] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [showFaleConosco, setShowFaleConosco] = useState(showFaleConoscoBool);
  const [showLocalizacao, setShowLocalizacao] = useState(showLocalizacaoBool);
  const [showTrabalheConosco, setShowTrabalheConosco] = useState(showTrabalheConoscoBool);
  const navigate = useNavigate();

  const sendEmailFormSchema = yup.object().shape({
    name: yup.string().required('Nome obrigatório'),
    email: yup.string().required('Email obrigatório ').email('Insira um e-mail válido'),
    telephone: yup.string().matches(/^(\d{2}\s?\d{5}[-\s]?\d{4})$/, 'Formato de telefone inválido').required('Número de Telefone obrigatório'),
    emailSubject: yup.string().required('Assunto obrigatório'),
    message: yup.string().required('Mensagem obrigatória'),
    contactArea: yup.string().typeError('Área para contato obrigatória').required('Área para contato obrigatória'),
  });

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(sendEmailFormSchema)
  });

  const handleSendEmail = async (values: any) => {
    const data = {
      service_id: process.env.REACT_APP_EMAILJS_SERVICE_ID,
      template_id: process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      user_id: process.env.REACT_APP_EMAILJS_USER_ID,
      template_params: {
        'from_name': values.name,
        'to_email': values.contactArea,
        'from_email': values.email,
        'from_telephone': values.telephone,
        'email_subject': values.emailSubject,
        'message': values.message,
      }
    }
    try {
      setEmailLoading(true);
      const response = await emailApi.post('/email/send', data);

      if (response.status === 200) {
        setEmailLoading(false);
        setEmailSuccess(true);

        setTimeout(() => {
          navigate('/')
        }, 5000);

      } else {
        setEmailLoading(false);
        setEmailError(true);

        setTimeout(() => {
          setEmailSuccess(false);
          setEmailError(false);
        }, 5000)
      }
    } catch (err) {
      console.log(err);
      setEmailLoading(false);
      setEmailSuccess(false);

      setEmailError(true);
      setTimeout(() => {
        setEmailError(false);
      }, 5000)
    }
  }
  const location = useLocation();

  useEffect(() => {
    if (process.env.REACT_APP_EMAILJS_SERVICE_ID) {
      const userId = process.env.REACT_APP_EMAILJS_SERVICE_ID
      emailjs.init(userId);
    }
  }, []);

  useEffect(() => {
    if (location.pathname === "/contato/fale-conosco") {
      setShowFaleConosco(true);
      setShowLocalizacao(false);
      setShowTrabalheConosco(false);
    }

    if (location.pathname === "/contato/localizacao") {
      setShowFaleConosco(false);
      setShowLocalizacao(true);
      setShowTrabalheConosco(false);
    }

    if (location.pathname === "/contato/trabalhe-conosco") {
      setShowFaleConosco(false);
      setShowLocalizacao(false);
      setShowTrabalheConosco(true);
    }

  }, [location]);

  return (
    <Main >
      <Helmet>
        <title>Contato Zanaflex</title>
        <meta name="description" content="Contato, Localização e Trabalhe Conosco - Zanaflex" />
        <meta name="keywords" content="Contato, Localização, Trabalhe Conosco, Zanaflex, Borracha, Composto de Borracha" />
      </Helmet>

      <SubMenu>
        <Link to="/contato/fale-conosco" className={showFaleConosco ? 'submenu-active' : 'submenu-disabled'} ><h1>Fale Conosco</h1></Link>
        <Link to="/contato/localizacao" className={showLocalizacao ? 'submenu-active' : 'submenu-disabled'}><h1>Localização</h1></Link>
        <Link to="/contato/trabalhe-conosco" className={showTrabalheConosco ? 'submenu-active' : 'submenu-disabled'}><h1>Trabalhe Conosco</h1></Link>
      </SubMenu>
      {showFaleConosco && (
        <Container data-aos="fade-right">
          <div>
            <Form onSubmit={handleSubmit(handleSendEmail)}>
              <h2>Contato</h2>
              <div>
                <label htmlFor="name">Nome</label>
                <Input error={errors.name} {...register('name')} />
              </div>
              <div>
                <label htmlFor="email">Email</label>
                <Input error={errors.email} {...register('email')} />
              </div>
              <div>
                <label htmlFor="telephone">DDD + Telefone</label>
                <Input error={errors.telephone} {...register('telephone')} />
              </div>
              <div>
                <label htmlFor="emailSubject">Assunto</label>
                <Input error={errors.emailSubject} {...register('emailSubject')} />
              </div>
              <div>
                <label htmlFor="message">Mensagem</label>
                <TextArea error={errors.message} {...register('message')} isTextArea={true} />
              </div>
              <div>
                <label htmlFor="contactArea">Selecione a área para contato</label>
                <Select error={errors.contactArea} {...register('contactArea')}>
                  <option value="" selected>Selecione ...</option>
                  <option value="comercial@zanaflex.com.br">Comercial</option>
                  <option value="desenvolvimento@zanaflex.com.br">Desenvolvimento</option>
                  <option value="qualidade@zanaflex.com.br">Qualidade</option>
                  <option value="rh@zanaflex.com.br">RH</option>
                  <option value="assistenciatecnica@zanaflex.com.br">Assistência Técnica</option>
                </Select>
              </div>
              {emailLoading === true ? (
                <button className="loading" disabled>
                  <span>Enviando...</span>
                </button>
              ) : emailSuccess === true ? (
                <div className="success">
                  <span>Seu e-mail foi enviado com sucesso!</span>
                  <p>Redirecionando...</p>
                </div>
              ) : emailError === true ? (
                <div className="success">
                  <span>Erro ao enviar o e-mail!</span>
                  <p>Tente novamente!</p>
                </div>
              ) : (
                <>
                  <small>Ao continuar com o acesso, você concorda com a nossa <Link to="/politica-de-privacidade">política de privacidade.</Link></small>
                  <button type="submit">Enviar Mensagem</button>
                </>
              )}
            </Form>
          </div>
        </Container>
      )}
      {showLocalizacao && (
        <Container data-aos="fade-right">
          <div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3656.2390774103974!2d-46.90437598447501!3d-23.595757268749704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cf00ba175e3e6f%3A0xc88580f0a400e21b!2sZanaflex%20Borrachas!5e0!3m2!1spt-BR!2sbr!4v1643202973756!5m2!1spt-BR!2sbr" title="Zanaflex Localização" loading="lazy"></iframe>
            <Localization>
              <span>ZANAFLEX®</span>
              <span>Zanaflex Borrachas Ltda.</span>
              <span>Av. Vasco Massafeli, 1010</span>
              <span>Cotia - SP - Brasil - CEP 06703-600</span>
              <span>Fone : +55 (11) 2165-3450</span>
              <span>Fax : +55 (11) 2165-3451</span>
              <a href="https://www.zanaflex.com.br" target="_blank" rel="noreferrer">www.zanaflex.com.br</a>
            </Localization>
          </div>
        </Container>
      )}
      {showTrabalheConosco && (
        <Container data-aos="fade-right">
          <div>
            <TrabalheConosco>
              <h2>Trabalhe com a gente</h2>
              <br /><br />
              <div>
                <h3>Faça parte do time Zanaflex!</h3>
              </div>
              <br />
              <div>
                As pessoas são o meio e o fim dos esforços da Zanaflex.
                <br />
                Por isso, nada mais natural que manter um ambiente de oportunidades baseado na ética, transparência e no crescimento pessoal e profissional.
              </div>
              <br /><br />
              <div>
                <b>Inscreva-se em nosso banco de talentos e participe de nossos processo seletivos!</b>
              </div>
              <br /><br />
              <div>
                <article>Envie seu currículo e pretensão salarial para:
                  <br />
                  <b><a>rh@zanaflex.com.br</a></b>
                </article>
              </div>
              <div className='curriculum'>
                <TrabalheConoscoForm />
              </div>
            </TrabalheConosco>
          </div>
        </Container>
      )}
    </Main>
  );
}


export function ContatoES({ showFaleConoscoBool = true, showLocalizacaoBool = false }: ContatoProps) {
  const [emailLoading, setEmailLoading] = useState(false);
  const [emailSuccess, setEmailSuccess] = useState(false);
  const [showFaleConosco, setShowFaleConosco] = useState(showFaleConoscoBool);
  const [showLocalizacao, setShowLocalizacao] = useState(showLocalizacaoBool);
  const navigate = useNavigate();

  const sendEmailFormSchema = yup.object().shape({
    name: yup.string().required('Nombre obligatorio'),
    email: yup.string().required('Correo electrónico requerido').email('Introduzca un correo electrónico válido'),
    telephone: yup.string().matches(/^(\d{2}\s?\d{5}[-\s]?\d{4})$/, 'Formato de teléfono no válido').required('Número de teléfono obligatorio'),
    emailSubject: yup.string().required('Asunto obligatorio'),
    message: yup.string().required('Mensaje obligatorio'),
    contactArea: yup.string().typeError('Zona de contacto obligatoria').required('Zona de contacto obligatoria'),
  });

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(sendEmailFormSchema)
  });

  const handleSendEmail = async (values: any) => {
    const data = {
      service_id: process.env.REACT_APP_EMAILJS_SERVICE_ID,
      template_id: process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      user_id: process.env.REACT_APP_EMAILJS_USER_ID,
      template_params: {
        'from_name': values.name,
        'to_email': values.contactArea,
        'from_email': values.email,
        'from_telephone': values.telephone,
        'email_subject': values.emailSubject,
        'message': values.message,
      }
    }
    try {
      setEmailLoading(true);
      const response = await emailApi.post('/email/send', data);
      setEmailSuccess(true);
      console.log(response);
      await new Promise(resolve => setTimeout(resolve, 5000));
      navigate('/');
    } catch (err) {
      console.log(err);
      setEmailLoading(false);
      setEmailSuccess(false);
    }
  }
  const location = useLocation();

  useEffect(() => {
    if (process.env.REACT_APP_EMAILJS_SERVICE_ID) {
      const userId = process.env.REACT_APP_EMAILJS_SERVICE_ID
      emailjs.init(userId);
    }
  }, []);

  useEffect(() => {
    if (location.pathname === "/contato/fale-conosco/es-ES") {
      setShowFaleConosco(true);
      setShowLocalizacao(false);
    }

    if (location.pathname === "/contato/localizacao/es-ES") {
      setShowFaleConosco(false);
      setShowLocalizacao(true);
    }

    if (location.pathname === "/contato/trabalhe-conosco/es-ES") {
      setShowFaleConosco(false);
      setShowLocalizacao(true);
    }

  }, [location]);

  return (
    <Main >
      <Helmet>
        <title>Contacto Zanaflex</title>
        <meta name="description" content="Contacto, Ubicación y Trabaja con nosotros - Zanaflex" />
        <meta name="keywords" content="Contacto, Ubicación, Trabaja con nosotros, Zanaflex, Caucho, Compuesto de Caucho" />
      </Helmet>

      <SubMenu>
        <Link to="/contato/fale-conosco/es-ES" className={showFaleConosco ? 'submenu-active' : 'submenu-disabled'} ><h1>Hable con nosotros</h1></Link>
        <Link to="/contato/localizacao/es-ES" className={showLocalizacao ? 'submenu-active' : 'submenu-disabled'}><h1>Localización</h1></Link>
      </SubMenu>
      {showFaleConosco && (
        <Container data-aos="fade-right">
          <div>
            <Form onSubmit={handleSubmit(handleSendEmail)}>
              <h2>Contacto</h2>
              <div>
                <label htmlFor="name">Nombre</label>
                <Input error={errors.name} {...register('name')} />
              </div>
              <div>
                <label htmlFor="email">Correo electrónico</label>
                <Input error={errors.email} {...register('email')} />
              </div>
              <div>
                <label htmlFor="telephone">DDD + Teléfono</label>
                <Input error={errors.telephone} {...register('telephone')} />
              </div>
              <div>
                <label htmlFor="emailSubject">Tema en cuestion</label>
                <Input error={errors.emailSubject} {...register('emailSubject')} />
              </div>
              <div>
                <label htmlFor="message">Mensaje</label>
                <TextArea error={errors.message} {...register('message')} isTextArea={true} />
              </div>
              <div>
                <label htmlFor="contactArea">Seleccione el área de contacto</label>
                <Select error={errors.contactArea} {...register('contactArea')}>
                  <option value="" selected>Selecione ...</option>
                  <option value="comercial@zanaflex.com.br">Comercial</option>
                  <option value="desenvolvimento@zanaflex.com.br">Desarrollo</option>
                  <option value="qualidade@zanaflex.com.br">Calidad</option>
                  <option value="rh@zanaflex.com.br">RH</option>
                  <option value="assistencia.tecnica@zanaflex.com.br">Asistencia técnica</option>
                </Select>
              </div>
              {emailLoading === true ? (
                <button className="loading" disabled>
                  <span>Enviando...</span>
                </button>
              ) : emailSuccess === true ? (
                <div className="success">
                  <span>¡Su correo electrónico ha sido enviado existosamente!</span>
                  <p>Redireccionando...</p>
                </div>
              ) : (
                <>
                  <small>Al continuar accediendo, usted acepta nuestros <Link to="/politica-de-privacidade/es-ES">Política de privacidad.</Link></small>
                  <button type="submit">Enviar mensaje</button>
                </>
              )}
            </Form>
          </div>
        </Container>
      )}
      {showLocalizacao && (
        <Container data-aos="fade-right">
          <div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3656.2390774103974!2d-46.90437598447501!3d-23.595757268749704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cf00ba175e3e6f%3A0xc88580f0a400e21b!2sZanaflex%20Borrachas!5e0!3m2!1spt-BR!2sbr!4v1643202973756!5m2!1spt-BR!2sbr" title="Zanaflex Localização" loading="lazy"></iframe>
            <Localization>
              <span>ZANAFLEX®</span>
              <span>Zanaflex Borrachas Ltda.</span>
              <span>Av. Vasco Massafeli, 1010</span>
              <span>Cotia - SP - Brasil - CEP 06703-600</span>
              <span>Fone : +55 (11) 2165-3450</span>
              <span>Fax : +55 (11) 2165-3451</span>
              <a href="https://www.zanaflex.com.br" target="_blank" rel="noreferrer">www.zanaflex.com.br</a>
            </Localization>
          </div>
        </Container>
      )}
    </Main>
  );
}


export function ContatoUS({ showFaleConoscoBool = true, showLocalizacaoBool = false }: ContatoProps) {
  const [emailLoading, setEmailLoading] = useState(false);
  const [emailSuccess, setEmailSuccess] = useState(false);
  const [showFaleConosco, setShowFaleConosco] = useState(showFaleConoscoBool);
  const [showLocalizacao, setShowLocalizacao] = useState(showLocalizacaoBool);
  const navigate = useNavigate();

  const sendEmailFormSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    email: yup.string().required('Email is required').email('Enter a valid email'),
    telephone: yup.string().matches(/^(\d{2}\s?\d{5}[-\s]?\d{4})$/, 'Not valid phone format').required('Mandatory telephone number'),
    emailSubject: yup.string().required('Subject is required'),
    message: yup.string().required('Message is required'),
    contactArea: yup.string().typeError('Contact area is required').required('Contact area is required'),
  });

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(sendEmailFormSchema)
  });

  const handleSendEmail = async (values: any) => {
    const data = {
      service_id: process.env.REACT_APP_EMAILJS_SERVICE_ID,
      template_id: process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      user_id: process.env.REACT_APP_EMAILJS_USER_ID,
      template_params: {
        'from_name': values.name,
        'to_email': values.contactArea,
        'from_email': values.email,
        'from_telephone': values.telephone,
        'email_subject': values.emailSubject,
        'message': values.message,
      }
    }
    try {
      setEmailLoading(true);
      const response = await emailApi.post('/email/send', data);
      setEmailSuccess(true);
      console.log(response);
      await new Promise(resolve => setTimeout(resolve, 5000));
      navigate('/');
    } catch (err) {
      console.log(err);
      setEmailLoading(false);
      setEmailSuccess(false);
    }
  }
  const location = useLocation();

  useEffect(() => {
    if (process.env.REACT_APP_EMAILJS_SERVICE_ID) {
      const userId = process.env.REACT_APP_EMAILJS_SERVICE_ID
      emailjs.init(userId);
    }
  }, []);

  useEffect(() => {
    if (location.pathname === "/contato/fale-conosco/en-US") {
      setShowFaleConosco(true);
      setShowLocalizacao(false);
    }

    if (location.pathname === "/contato/localizacao/en-US") {
      setShowFaleConosco(false);
      setShowLocalizacao(true);
    }

    if (location.pathname === "/contato/trabalhe-conosco/en-US") {
      setShowFaleConosco(false);
      setShowLocalizacao(true);
    }

  }, [location]);

  return (
    <Main >
      <Helmet>
        <title>Zanaflex Contact</title>
        <meta name="description" content="Contact, Location and Work with us - Zanaflex" />
        <meta name="keywords" content="Contact, Location, Work with us, Zanaflex, Rubber, Rubber Compound" />
      </Helmet>

      <SubMenu>
        <Link to="/contato/fale-conosco/en-US" className={showFaleConosco ? 'submenu-active' : 'submenu-disabled'} ><h1>Contact us</h1></Link>
        <Link to="/contato/localizacao/en-US" className={showLocalizacao ? 'submenu-active' : 'submenu-disabled'}><h1>Localization</h1></Link>
      </SubMenu>
      {showFaleConosco && (
        <Container data-aos="fade-right">
          <div>
            <Form onSubmit={handleSubmit(handleSendEmail)}>
              <h2>Contact</h2>
              <div>
                <label htmlFor="name">Name</label>
                <Input error={errors.name} {...register('name')} />
              </div>
              <div>
                <label htmlFor="email">Email</label>
                <Input error={errors.email} {...register('email')} />
              </div>
              <div>
                <label htmlFor="telephone">DDD + Telephone</label>
                <Input error={errors.telephone} {...register('telephone')} />
              </div>
              <div>
                <label htmlFor="emailSubject">Subject</label>
                <Input error={errors.emailSubject} {...register('emailSubject')} />
              </div>
              <div>
                <label htmlFor="message">Message</label>
                <TextArea error={errors.message} {...register('message')} isTextArea={true} />
              </div>
              <div>
                <label htmlFor="contactArea">Select the contact area</label>
                <Select error={errors.contactArea} {...register('contactArea')}>
                  <option value="" selected>Selecione ...</option>
                  <option value="comercial@zanaflex.com.br">Commercial</option>
                  <option value="desenvolvimento@zanaflex.com.br">Development</option>
                  <option value="qualidade@zanaflex.com.br">Quality</option>
                  <option value="rh@zanaflex.com.br">HR</option>
                  <option value="assistencia.tecnica@zanaflex.com.br">Technical assistance</option>
                </Select>
              </div>
              {emailLoading === true ? (
                <button className="loading" disabled>
                  <span>Sending...</span>
                </button>
              ) : emailSuccess === true ? (
                <div className="success">
                  <span>Your email has been successfully sent!</span>
                  <p>Redirecting......</p>
                </div>
              ) : (
                <>
                  <small>By continuing to access, you agree to our <Link to="/politica-de-privacidade/us-US">Privacy Policy.</Link></small>
                  <button type="submit">Send Message</button>
                </>
              )}
            </Form>
          </div>
        </Container>
      )}
      {showLocalizacao && (
        <Container data-aos="fade-right">
          <div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3656.2390774103974!2d-46.90437598447501!3d-23.595757268749704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cf00ba175e3e6f%3A0xc88580f0a400e21b!2sZanaflex%20Borrachas!5e0!3m2!1spt-BR!2sbr!4v1643202973756!5m2!1spt-BR!2sbr" title="Zanaflex Localização" loading="lazy"></iframe>
            <Localization>
              <span>ZANAFLEX®</span>
              <span>Zanaflex Borrachas Ltda.</span>
              <span>Av. Vasco Massafeli, 1010</span>
              <span>Cotia - SP - Brasil - CEP 06703-600</span>
              <span>Fone : +55 (11) 2165-3450</span>
              <span>Fax : +55 (11) 2165-3451</span>
              <a href="https://www.zanaflex.com.br" target="_blank" rel="noreferrer">www.zanaflex.com.br</a>
            </Localization>
          </div>
        </Container>
      )}
    </Main>
  );
}
