import formatosImg from '../../assets/formatos-de-distribuicao.png';
import ReactPlayer from 'react-player';
import Button from '../../components/Button';

import { Main, Container, Square, Carousel } from './styles';

import { register } from 'swiper/element/bundle'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import { Swiper, SwiperSlide } from 'swiper/react'
import { Helmet } from 'react-helmet';

register();

export function Home() {

  const data = [
    { id: '1', url: 'https://znfx.com.br/files/vinheta.mp4' }
  ]

  return (
    <Main>
      <Helmet>
        <title>ZANAFLEX - Soluções Tecnológicas em Produtos Elastômeros</title>
        <meta name="description" content="Inovação e excelência para oferecer ampla diversidade de serviços" />
        <meta name="keywords" content="Elastômeros, Tecnologia, Zanaflex, Borracha, Composto de Borracha" />
      </Helmet>

      <Carousel>
        <Swiper
          style={{ width: "100%" }}
          slidesPerView={1}
          pagination={{ clickable: true }}
          navigation
        >
          {data.map((item) => (
            <SwiperSlide key={item.id}>
              <ReactPlayer url={item.url}
                width="100%"
                playing={true}
                muted={true}
                controls={true}
                loop={true} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Carousel>

      <Container>
        <div>
          <h1>Ótimos resultados em repetibilidade, consistência e excelente qualidade final</h1>
          <p>A Zanaflex é líder no mercado brasileiro de compostos de borracha, uma empresa com capacidade para desenvolver e produzir produtos personalizados para as mais diversas aplicações. Nossos clientes são nossos parceiros, por isso privilegiamos o cumprimento de prazos, e a dedicação à qualidade através do investimento em equipamentos cada vez mais sofisticados.</p>
          <Button pathname="/produtos/compostos-de-borracha" content="Saiba Mais" />
        </div>
        <div>
          <h1>Formatos de Distribuição</h1>
          <p>Em tiras para injetoras, mantas contínuas ou escontínuas, calandrado, granulado e pré-formados.</p>
          <img src={formatosImg} alt="Formatos de distribuição" />
          <Button pathname="/logistica/formatos-dos-compostos" content="Saiba Mais" />
        </div>
      </Container>
      <Container>
        <div data-aos="fade-right">
          <h1>Capacidade e Flexibilidade de Processamento</h1>
          <p>Nossos clientes são nossos parceiros, por isso privilegiamos o cumprimento de prazos, e a dedicação à qualidade através do investimento em equipamentos cada vez mais sofisticados. Principais elastômeros utilizados: NR, SBR, NBR, CSM, IIR, IR, CR, EPDM, BR, AEM, CPE.</p>
          <Button pathname="/infraestrutura/instalacoes" content="Saiba Mais" />
        </div>
        <Square data-aos="fade-left" data-aos-duration="1500">
          <h1>Vantagens de Nossos Serviços e Produtos</h1>
          <ul>
            <li>Equipamentos tecnologicamente atualizados de acordo com as necessidades de mercado</li>
            <li>Alto grau de repetibilidade e qualidade</li>
            <li>Sigilo e Confiabilidade</li>
            <li> Tornamos nossos clientes mais competitivos</li>
          </ul>
          <p>Em tiras para injetoras, mantas contínuas ou escontínuas, calandrado, granulado e pré-formados.</p>
        </Square>
      </Container>
    </Main>
  );
}

export function HomeES() {

  const data = [
    { id: '1', url: 'https://znfx.com.br/files/vinheta.mp4' }
  ]

  return (
    <Main>
      <Helmet>
        <title>ZANAFLEX - Soluciones Tecnológicas en Productos Elastómeros</title>
        <meta name="description" content="Innovación y excelencia para ofrecer una amplia gama de servicios" />
        <meta name="keywords" content="Elastómeros, Tecnología, Zanaflex, Caucho, Compuesto de Caucho" />
      </Helmet>

      <Carousel>
        <Swiper
          style={{ width: "100%" }}
          slidesPerView={1}
          pagination={{ clickable: true }}
          navigation
        >
          {data.map((item) => (
            <SwiperSlide key={item.id}>
              <ReactPlayer url={item.url}
                width="100%"
                playing={true}
                muted={true}
                controls={true}
                loop={true} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Carousel>

      <Container>
        <div>
          <h1>Grandes resultados en repetibilidad, consistencia y excelente calidad final</h1>
          <p>Zanaflex es líder en el mercado brasileño de compuestos de caucho, una empresa con capacidad para desarrollar y producir productos personalizados para las más diversas aplicaciones. Nuestros clientes son nuestros socios, por lo que priorizamos el cumplimiento de los plazos y la dedicación a la calidad mediante la inversión en equipos cada vez más sofisticados.</p>
          <Button pathname="/produtos/compostos-de-borracha/es-ES" content="Sepa mas" />
        </div>
        <div>
          <h1>Formatos de distribución</h1>
          <p>En tiras para moldeo por inyección, mantillas continuas o discontinuas, calandradas, granuladas y preformadas.</p>
          <img src={formatosImg} alt="Formatos de distribución" />
          <Button pathname="/logistica/formatos-dos-compostos/es-ES" content="Sepa mas" />
        </div>
      </Container>
      <Container>
        <div data-aos="fade-right">
          <h1>Capacidad de procesamiento y flexibilidad</h1>
          <p>Nuestros clientes son nuestros socios, por lo que priorizamos el cumplimiento de los plazos y la dedicación a la calidad mediante la inversión en equipos cada vez más sofisticados. Principales elastómeros utilizados: NR, SBR, NBR, CSM, IIR, IR, CR, EPDM, BR, AEM, CPE.</p>
          <Button pathname="/infraestrutura/instalacoes/es-ES" content="Sepa mas" />
        </div>
        <Square data-aos="fade-left" data-aos-duration="1500">
          <h1>Ventajas de Nuestros Servicios y Productos</h1>
          <ul>
            <li>Equipos tecnológicamente actualizados de acuerdo a las necesidades del mercado</li>
            <li>Alto grado de repetibilidad y calidad</li>
            <li>Confidencialidad y Confiabilidad</li>
            <li>Hacemos más competitivos a nuestros clientes</li>
          </ul>
          <p>En tiras para moldeo por inyección, mantillas continuas o discontinuas, calandradas, granuladas y preformadas.</p>
        </Square>
      </Container>
    </Main>
  );
}

export function HomeUS() {

  const data = [
    { id: '1', url: 'https://znfx.com.br/files/vinheta.mp4' }
  ]

  return (
    <Main>
      <Helmet>
        <title>ZANAFLEX - Technological Solutions in Elastomer Products</title>
        <meta name="description" content="Innovation and excellence to offer a wide range of services" />
        <meta name="keywords" content="Elastomers, Technology, Zanaflex, Rubber, Rubber Compound" />
      </Helmet>

      <Carousel>
        <Swiper
          style={{ width: "100%" }}
          slidesPerView={1}
          pagination={{ clickable: true }}
          navigation
        >
          {data.map((item) => (
            <SwiperSlide key={item.id}>
              <ReactPlayer url={item.url}
                width="100%"
                playing={true}
                muted={true}
                controls={true}
                loop={true} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Carousel>

      <Container>
        <div>
          <h1>Great results in repeatability, consistency and excellent final quality</h1>
          <p>Zanaflex is the leader in the Brazilian rubber compound market, a company with the capacity to develop and produce customized products for the most diverse applications. Our customers are our partners, so we prioritize meeting deadlines and dedication to quality through investment in increasingly sophisticated equipment.</p>
          <Button pathname="/produtos/compostos-de-borracha" content="Know more" />
        </div>
        <div>
          <h1>Distribution Formats</h1>
          <p>In strips for injection molding, continuous or discontinuous mats, calendered, granulated and preformed.</p>
          <img src={formatosImg} alt="Distribution Formats" />
          <Button pathname="/logistica/formatos-dos-compostos/us-US" content="Know More" />
        </div>
      </Container>
      <Container>
        <div data-aos="fade-right">
          <h1>Processing Capacity and Flexibility</h1>
          <p>Our customers are our partners, so we prioritize meeting deadlines and dedication to quality through investment in increasingly sophisticated equipment. Main elastomers used: NR, SBR, NBR, CSM, IIR, IR, CR, EPDM, BR, AEM, CPE.</p>
          <Button pathname="/infraestrutura/instalacoes/us-US" content="Know More" />
        </div>
        <Square data-aos="fade-left" data-aos-duration="1500">
          <h1>Advantages of Our Services and Products</h1>
          <ul>
            <li>Technologically updated equipment according to market needs</li>
            <li>High degree of repeatability and quality</li>
            <li>Confidentiality and Reliability</li>
            <li>We make our customers more competitive</li>
          </ul>
          <p>In strips for injection molding, continuous or discontinuous mats, calendered, granulated and preformed.</p>
        </Square>
      </Container>
    </Main>
  );
}