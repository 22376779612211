import React from 'react';
import { Link } from 'react-router-dom';
import vantagensImg from '../../assets/vantagens.png';
import SAPImg from '../../assets/sap.png';
import { Main, Container, SubMenu, Square } from './styles';
import { Helmet } from 'react-helmet';

export function DT() {
  return (
    <Main>
      <Helmet>
        <title>D&T Zanaflex</title>
        <meta name="description" content="Desenvolvimento e Tecnologia Zanaflex" />
        <meta name="keywords" content="Desenvolvimento, Tecnologia, Zanaflex, Borracha, Composto de Borracha" />
      </Helmet>

      <SubMenu>
        <Link to="/dt">
          <h1>
            Desenvolvimento e Tecnologia
          </h1>
        </Link>
      </SubMenu>
      <Container>
        <div data-aos="fade-right">
          <h2>A Zanaflex atua em pesquisas de novas matérias-primas e desenvolvimento para o atendimento de novas aplicações.</h2>
          <p>Seu departamento de engenharia integra o cadastramento de receitas ao desenvolvimento do processo de mistura, fazendo uma análise crítica das necessidades do cliente quanto ao processo, especificação técnica, forma de fornecimento, embalagem e custo efetivo.</p>
        </div>
        <div>
          <img src={vantagensImg} alt="Formatos de distribuição" />
        </div>
      </Container>
      <Container>
        <div>
          <h2>Vantagens de Nossos Serviços e Produtos</h2>
          <ul>
            <li>Qualidade, sigilo e confiabilidade.</li>
            <li>Equipamentos modernos de mistura.</li>
            <li>Alto grau de repetibilidade e qualidade.</li>
            <li>Redução de custos de fabricação e terceirização (mão de obra, licenças e inventários).</li>
            <li>Permite ao cliente canalizar investimentos no setor de vulcanização, tornando sua empresa mais competitiva.</li>
            <li>Adequação ao cliente quanto à sazonalidade de mercado</li>
            <li>Limpeza de fábrica (especialmente quando não se deseja manusear o negro de fumo).</li>
          </ul>
        </div>
        <Square data-aos="fade-left" data-aos-duration="1500">
          <img src={SAPImg} alt="SAP" />
          <p>Com foco dedicado aos compostos de borracha, a Zanaflex utiliza os melhores equipamentos e as melhores inovações de mercado para produzir um composto de borracha de alta tecnologia. Onde é possível, a Zanaflex introduz um alto grau de automação e sempre se dedica a melhorar suas linhas de produção em conjunto com os melhores fabricantes de equipamentos do mercado; utilizando o ERP SAP Best Practicespara garantir seus processos e a integralidade de suas informações.</p>
        </Square>
      </Container>
    </Main>
  );
}

export function DTES() {
  return (
    <Main>
      <Helmet>
        <title>D&T Zanaflex</title>
        <meta name="description" content="Desarrollo y Tecnología Zanaflex" />
        <meta name="keywords" content="Desarrollo, Tecnología, Zanaflex, Caucho, Compuesto de Caucho" />
      </Helmet>

      <SubMenu>
        <Link to="/dt/es-ES">
          <h1>
            Desarrollo y Tecnología
          </h1></Link>
      </SubMenu>
      <Container>
        <div data-aos="fade-right">
          <h2>Zanaflex trabaja en la investigación de nuevas materias primas y desarrollo para atender nuevas aplicaciones.</h2>
          <p>Su departamento de ingeniería integra el registro de recetas con el desarrollo del proceso de mezclado, realizando un análisis crítico de las necesidades del cliente en cuanto a proceso, especificación técnica, forma de suministro, empaque y costo efectivo.</p>
        </div>
        <div>
          <img src={vantagensImg} alt="Formatos de distribución" />
        </div>
      </Container>
      <Container>
        <div>
          <h2>Ventajas de Nuestros Servicios y Productos</h2>
          <ul>
            <li>Calidad, secreto y fiabilidad.</li>
            <li>Equipo de mezcla moderno.</li>
            <li>Alto grado de repetibilidad y calidad.</li>
            <li>Reducción de costes de fabricación y externalización (mano de obra, licencias e inventarios).</li>
            <li>Permite al cliente canalizar inversiones en el sector de la vulcanización, haciendo más competitiva su empresa.</li>
            <li>Adaptación al cliente en función de la estacionalidad del mercado</li>
            <li>Limpieza de fábrica (especialmente cuando no se quiere manipular el negro de humo).</li>
          </ul>
        </div>
        <Square data-aos="fade-left" data-aos-duration="1500">
          <img src={SAPImg} alt="SAP" />
          <p>Con un enfoque dedicado a los compuestos de caucho, Zanaflex utiliza los mejores equipos y las mejores innovaciones del mercado para producir un compuesto de caucho de alta tecnología. Siempre que sea posible, Zanaflex introduce un alto grado de automatización y siempre se dedica a mejorar sus líneas de producción junto con los mejores fabricantes de equipos del mercado; utilizando ERP SAP Best Practices para garantizar sus procesos y la integridad de su información.</p>
        </Square>
      </Container>
    </Main>
  );
}

export function DTUS() {
  return (
    <Main>
      <Helmet>
        <title>Zanaflex D&T</title>
        <meta name="description" content="Development and Technology Zanaflex" />
        <meta name="keywords" content="Development, Technology, Zanaflex, Rubber, Rubber Compound" />
      </Helmet>

      <SubMenu>
        <Link to="/dt/en-US">
          <h1>
            Development and Technology
          </h1>
        </Link>
      </SubMenu>
      <Container>
        <div data-aos="fade-right">
          <h2>Zanaflex works in research of new raw materials and development to meet new applications.</h2>
          <p>Its engineering department integrates the registration of recipes with the development of the mixing process, making a critical analysis of the customer's needs regarding the process, technical specification, form of supply, packaging and effective cost.</p>
        </div>
        <div>
          <img src={vantagensImg} alt="Distribution formats" />
        </div>
      </Container>
      <Container>
        <div>
          <h2>Advantages of Our Services and Products</h2>
          <ul>
            <li>Quality, secrecy and reliability.</li>
            <li>Modern mixing equipment.</li>
            <li>High degree of repeatability and quality.</li>
            <li>Reduction of manufacturing and outsourcing costs (labor, licenses and inventories).</li>
            <li>It allows the customer to channel investments in the vulcanization sector, making their company more competitive.</li>
            <li>Adaptation to the customer in terms of market seasonality</li>
            <li>Factory cleaning (especially when you do not want to handle carbon black).</li>
          </ul>
        </div>
        <Square data-aos="fade-left" data-aos-duration="1500">
          <img src={SAPImg} alt="SAP" />
          <p>With a dedicated focus on rubber compounds, Zanaflex uses the best equipment and the best market innovations to produce a high-tech rubber compound. Where possible, Zanaflex introduces a high degree of automation and is always dedicated to improving its production lines together with the best equipment manufacturers on the market; using ERP SAP Best Practices to guarantee your processes and the completeness of your information.</p>
        </Square>
      </Container>
    </Main>
  );
}
